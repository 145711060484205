// Navbar styles

.toggle-nav {
  background-color: transparent;
  cursor: pointer;
  box-shadow: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0.5rem; 
}

.nav-list {
  background-color: transparent;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  left: -15rem;
  width: 0;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d; 
}

.nav-list.-open {
  width: 100%;
  left: 0;
  padding-bottom: 4.5rem; 
}

.nav-list.-open > .list.-left {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  -webkit-overflow-scrolling: touch; 
}

.nav-list > .list.-left {
  background: #25282d;
  position: relative;
  width: 52%;
  opacity: 0.98;
  height: 100%; 
}

.nav-list > .list.-right {
  width: 40%; 
}

.nav-list > .list > .item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid rgba(176, 176, 176, 0.5); 
}

.nav-list > .list > .item:last-child {
  border-bottom: 0; 
}

.nav-list > .list > .item > .link {
  border-color: transparent;
  padding: 0.75rem 1rem 0.5rem 1rem;
  flex-basis: 100%;
  display: block;
  background: transparent;
  font-size: 1.5rem;
  padding-left: 1.5rem;
  color: #e0e0e0;
  transition: all 0.3s ease-in-out; 
}

.nav-list > .list > .item > .link:hover, .nav-list > .list > .item > .link.-active {
  color: #7dc580;
}

.nav-list > .list > .overlay {
  width: 40%;
  height: 100%;
  background: rgba(59, 59, 59, 0.65);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; 
}

@media all and (min-width: 800px) {
  .nav-list {
    top: unset;
    left: unset;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: initial;
    background: transparent;
    overflow: hidden;
  }
  .nav-list > .list.-left, .nav-list > .list.-right {
    overflow: hidden; 
  }
  .nav-list > .list.-left.-left, .nav-list > .list.-right.-left {
    height: 100%;
    width: 100%;
    border-top: 0;
    background: transparent;
    display: flex;
    align-items: center; 
  }

  .nav-list > .list > .item {
    margin-left: 1.5rem;
    margin-bottom: 0;
    border-bottom-color: transparent;
    justify-content: flex-start; 
  }
  .nav-list > .list > .item > .link {
    border-bottom: 2px solid transparent;
    display: block;
    flex-basis: unset;
    background: transparent;
    padding: 1.25rem 0;
    font-size: 1rem;
    outline: 0;
    transition: all 0.2s ease-in-out; 
  } 
}

._disableScroll {
  overflow-y: hidden !important; 
}

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 10px;
  cursor: pointer;
  -webkit-transition: .2s all;
  -o-transition: .2s all;
  transition: .2s all; }
  .burger:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%; 
  }
  .burger > .burger-lines {
    top: 50%;
    margin-top: -0.125em;
   }
    .burger > .burger-lines:before {
      left: 0;
      top: 1em; 
    }
    .burger > .burger-lines:after {
      left: 0;
      top: -1em; 
    }
  .burger.-offset > .burger-lines {
    top: 50%;
    margin-top: -0.125em; }
    .burger.-offset > .burger-lines:before {
      left: 1em;
      top: 1em; 
    }
    .burger.-offset > .burger-lines:after {
      left: 0;
      top: -1em; 
    }
  .burger.-squeeze .burger-lines,
  .burger.-squeeze .burger-lines:before,
  .burger.-squeeze .burger-lines:after {
    -webkit-transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
    -o-transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
    transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; }
  .burger.-squeeze .burger-lines:after {
    left: 0;
    top: -1em; }
  .burger.-squeeze .burger-lines:before {
    left: 0;
    top: 1em; }
  .burger.-squeeze.-offset .burger-lines:before,
  .burger.-squeeze.-offset .burger-lines:after {
    width: 2em; 
  }
  .burger.-squeeze.-offset .burger-lines:after {
    left: 0;
    top: -1em; 
  }
  .burger.-squeeze.-offset .burger-lines:before {
    left: 1em;
    top: 1em; 
  }
  .burger.-squeeze.open .burger-lines,
  .burger.-squeeze.open .burger-lines:before,
  .burger.-squeeze.open .burger-lines:after {
    -webkit-transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
    -o-transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
    transition: .2s background-color, .2s top, .2s left, .2s transform .15s; 
  }
  .burger.-squeeze.open .burger-lines {
    background-color: transparent; 
  }
  .burger.-squeeze.open .burger-lines:before,
  .burger.-squeeze.open .burger-lines:after {
    left: 0.5em;
    top: 0px; 
  }
  .burger.-squeeze.open .burger-lines:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); 
  }
  .burger.-squeeze.open .burger-lines:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
  }

.burger-lines,
.burger-lines:after,
.burger-lines:before {
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  border-radius: 0.25em;
  background-color: #e0e0e0;
  height: 0.25em;
  position: absolute;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0); 
}

.burger.-squeeze > .burger-lines {
  top: 50%;
  margin-top: -0.125em; 
}
  .burger.-squeeze > .burger-lines, .burger.-squeeze > .burger-lines:after, .burger.-squeeze > .burger-lines:before {
    transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; 
  }
  .burger.-squeeze > .burger-lines:after 
  {
    left: 0;
    top: -1em; 
  }
  .burger.-squeeze > .burger-lines:before {
    left: 0;
    top: 1em; 
  }

.burger.-squeeze.-offset > .burger-lines, .burger.-squeeze.-offset > .burger-lines:after, .burger.-squeeze.-offset > .burger-lines:before {
  transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; }

.burger.-squeeze.-offset > .burger-lines:after, .burger.-squeeze.-offset > .burger-lines:before {
  width: 3em; }

.burger.-squeeze.-offset > .burger-lines:after {
  left: 0;
  top: -1em; 
}

.burger.-squeeze.-offset > .burger-lines:before {
  left: 0em;
  top: 1em; 
}

.burger.-squeeze.open > .burger-lines, .burger.-squeeze.open > .burger-lines:after, .burger.-squeeze.open > .burger-lines:before {
  transition: .2s background-color, .2s top, .2s left, .2s transform .15s; 
}

.burger.-squeeze.open > .burger-lines:after, .burger.-squeeze.open > .burger-lines:before {
  width: 2em; 
}

.burger.-squeeze.open > .burger-lines {
  background-color: transparent; 
}

.burger.-squeeze.open > .burger-lines:before, .burger.-squeeze.open > .burger-lines:after {
  left: 0.5em;
  top: 0px; 
}

.burger.-squeeze.open > .burger-lines:before {
  transform: rotate(-45deg); 
}

.burger.-squeeze.open > .burger-lines:after {
  transform: rotate(45deg); 
}

.toggle-nav:focus {
  outline: 1px solid #2BD3EF;
}
