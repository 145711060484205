@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');

@mixin poppins-font-face($font) {
  font-family: $font;
  src: url("/assets/fonts/poppins-latin/#{$font}.ttf") format('truetype');
}

@mixin open-sans-font-face($font) {
  font-family: $font;
  src: url("/assets/fonts/open-sans/#{$font}.ttf") format('truetype');
}

@font-face {
  @include poppins-font-face(PoppinsLatin-Bold);
}

@font-face {
  @include poppins-font-face(PoppinsLatin-SemiBold);
}

@font-face {
  @include open-sans-font-face(OpenSans-Regular);
}

@font-face {
  @include open-sans-font-face(OpenSans-SemiBold);
}

@font-face {
  @include open-sans-font-face(OpenSans-Bold);
}


