@import "~bootstrap/scss/bootstrap";
@import "_font";
@import "_navbar";

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  background: #fff;
  color: #25282D;
  font-family: OpenSans-Regular;
}

a {
  color: #25282D;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #0a98c0;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'OpenSans-Regular';
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #1bb1dc;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #1bb1dc;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

#topbar {
  padding: 0 0 10px 0;
  font-size: 14px;
  transition: all 0.5s;
}

#topbar .social-links {
  text-align: right;
}

#topbar .social-links a {
  color: #535074;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
}

#topbar .social-links a:hover {
  color: #1bb1dc;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  height: 110px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 15px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 99999;
}

#header.header-scrolled,
#header.header-pages {
  height: 70px;
  padding: 15px 0;
  background-color: #25282d;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
}

#header.header-scrolled #topbar,
#header.header-pages #topbar {
  display: none;
}

#header .logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #2BD3EF;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 7px 0;
  max-height: 26px;
  width: 145px;
  height: 34px;
}

.footer-logo {
  width: 145px;
  height: 45px;
  padding: 0px;
  margin-bottom: 20px;
}

.main-pages {
  margin-top: 60px;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

#intro {
  width: 100%;
  position: relative;
  background: #25282D;
  background-size: cover;
  padding-top: 75px;
}

#intro .intro-info {
  position: absolute !important;
  top: 35px;
  margin: 90px auto;
}

#intro .intro-info h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 72px;
  font-family: 'PoppinsLatin-SemiBold';
  line-height: 62px;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, .6);
}

#intro .intro-info p {
  color: #fff;
  margin-bottom: 40px;
  font-size: 40px;
  text-align: center;
  font-family: 'OpenSans-Regular';
  line-height: 50px;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, .6);
}

.install-command {
  font-size: 24px;
  font-family: courier !important;
  border: 2px solid #2BD3EF;
  color:
    #fff;
  padding: 15px 0px;
  border-radius: 9px;
  background:
    #25282D;
  margin-left: 10px;
  width: 537px;
  margin: 0px auto;
}

.install-command input {
  font-size: 24px;
  font-family: courier !important;
  color: #fff;
  background: #25282D;
  border: 0px;
  width: 335px;
}

.align-self-center {
  text-align: center !important;
}

#intro .intro-info .btn-get-started,
#intro .intro-info .btn-services {
  font-family: 'OpenSans-Bold';
  font-size: 24px;
  text-transform: capitalize;
  letter-spacing: 1px;
  display: inline-block;
  padding: 7px 32px 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  color: #25282D;
  background: #2bd3ef;
  margin-left: 25px;
  border: 2px solid #2bd3ef;
}

#intro .intro-info .btn-get-started:hover {
  border: 2px solid #2bd3ef;
  background-color: #25282D;
  color: #2BD3EF;
  cursor: pointer;
}

#intro .intro-info .btn-get-started:hover:active {
  border: 2px solid #1FAEC6;
  background-color: #1FAEC6;
  color: #25282D;
}

.tag-line p {
  font-family: 'OpenSans-SemiBold';
  font-size: 21px;
}

.tag-line {
  margin-top: 110px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Desktop Navigation */

.main-nav {
  /* Drop Down */
  /* Deep Drop Down */
}

.main-nav,
.main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav>ul>li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.main-nav a {
  display: block;
  position: relative;
  color: #fff;
  transition: 0.3s;
  font-size: 18px;
  font-family: "OpenSans-Bold";
  text-transform: capitalize;
  font-weight: bold;
}

.main-nav a:hover,
.main-nav li:hover>a {
  color: #2BD3EF;
  text-decoration: none;
}

.main-nav .active>a {
  color: #1FAEC6;
  text-decoration: none;
}

.main-nav .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.main-nav .drop-down:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.main-nav .drop-down li {
  min-width: 180px;
  position: relative;
}

.main-nav .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #065e77;
}

.main-nav .drop-down ul a:hover,
.main-nav .drop-down ul .active>a,
.main-nav .drop-down ul li:hover>a {
  color: #1bb1dc;
}

.main-nav .drop-down>a:after {
  content: "\f107";
  font-family: FontAwesome;
  padding-left: 10px;
}

.main-nav .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.main-nav .drop-down .drop-down:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.main-nav .drop-down .drop-down>a {
  padding-right: 35px;
}

.main-nav .drop-down .drop-down>a:after {
  content: "\f105";
  position: absolute;
  right: 15px;
}

/* Mobile Navigation */

.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -260px;
  width: 260px;
  padding-top: 18px;
  background: rgba(40, 38, 70, 0.8);
  transition: 0.4s;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
}

.mobile-nav a:hover,
.mobile-nav .active>a,
.mobile-nav li:hover>a {
  color: #8dc2fa;
  text-decoration: none;
}

.mobile-nav .drop-down>a:after {
  content: "\f078";
  font-family: FontAwesome;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down>a:after {
  content: "\f077";
}

.mobile-nav .drop-down>a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  margin: 18px 18px 0 0;
  color: #fff;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(40, 38, 70, 0.8);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

/* Sections Header
--------------------------------*/

.section-header h3 {
  font-size: 34px;
  color: #25282D;
  text-align: center;
  position: relative;
  font-family: 'Poppins-Medium', sans-serif;
  margin-bottom: 70px;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #25282D;
  width: 50%;
}

/* Section with background
--------------------------------*/

.section-bg {
  background: #25282D;
}

.section-bg-white {
  background: #fff;
}

/* Services Section
--------------------------------*/

#services {
  padding: 50px 0 40px 0;
}

#services .box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0px 0px 30px 0px;
  background: #fcfcfc;
  /* box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out; */
  text-align: center;
}

.repl {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0px 0px 30px 0px;
  /* box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out; */
  text-align: center;
}
/* #services .box:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

#services .icon {
  margin: 0 auto 15px auto;
  padding-top: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

#services .icon i {
  font-size: 36px;
  line-height: 1;
}

#services .title {
  margin: 60px 0px 25px 0px;
  text-align: left;
  font-size: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

#services .title a {
  color: #111;
}

#services .box:hover .title a {
  color: #000;
}

#services .description {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 0;
  text-align: left;
  font-family: 'OpenSans-Regular';
  color: #25282D;
}

/* Features Section
--------------------------------*/

#features {
  padding: 80px 0;
}

#features h4 {
  font-weight: 600;
  font-size: 24px;
}

/* Testimonials Section
--------------------------------*/

#testimonials {
  padding: 90px 120px 30px 120px;
}

#testimonials .section-header {
  margin-bottom: 40px;
}

#testimonials .testimonial-item .testimonial-img {
  width: auto;
  border-radius: 50%;
  float: left;
}

#testimonials .testimonial-item h3 {
  font-size: 24px;
  margin: 0px 0 5px 0;
  color: #fff;
  line-height: 28px;
  font-family: 'PoppinsLatin-Bold';
  margin-left: 40px;
}

#testimonials .testimonial-item h3 span.sub-title {
  font-size: 18px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  display: block;
}

#testimonials .testimonial-item p {
  background: #fff;
  color: #25282D;
  padding: 21px;
  border-radius: 5px;
  font-size: 21px;
  font-family: 'OpenSans-Regular';
  margin-bottom: 20px;
}

#testimonials .owl-nav,
#testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#testimonials .owl-dot.active {
  background-color: #1bb1dc;
}

/*--------------------------------------------------------------
# Video Section
--------------------------------------------------------------*/
#video_container {
  margin-top: 70px;
}

.video-section {
  margin-bottom: 120px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  padding: 0 0 30px 0;
  font-size: 14px;
  color: #fff;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #25282D;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: 'OpenSans-Regular';
  color: #2BD3EF;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'OpenSans-Regular';
  color: #fff;
  padding-left: 5px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #1bb1dc;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #0a98c0;
  color: #fff;
}

#footer .footer-top h4 {
  font-family: 'OpenSans-Bold';
  font-size: 14px;
  color: #2BD3EF;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 10px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  line-height: 32px;
}

#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #535074;
  font-size: 15px;
}

#footer .credits {
  text-align: center;
  font-size: 14px;
  padding-top: 4px;
  color: #8582a8;
}

#footer .credits a {
  color: #1bb1dc;
}

#footer .credits a:hover {
  color: #0a98c0;
}

.circle_blue {
  width: 425px;
  height: 425px;
  background-color: transparent;
  border: 4px solid #2BD3EF;
  display: flex;
  justify-content: center;
  margin: 40px auto;
  border-radius: 50%;
  margin-bottom: 90px;
}

.github-star-block {
  border: 1px solid #29cde7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 120px;
}

.githubstar-box .github-icon {
  background: url(/assets/img/github_star.svg) no-repeat 0 0;
}

.githubstar-box .github-icon {
  background: url(/assets/img/github_star_count_dynamic.svg) no-repeat 0 0;
  padding-left: 140px;
  width: 40px;
  height: 18px;
}

span.github-star-count {
  position: absolute;
  color: #25282D;
  z-index: 9999;
  right: 10px;
  top: -8px;
  font-family: 'OpenSans-SemiBold';
  font-size: 18px;
}

.githubstar-box span {
  padding: 7px;
}

.clearfix-block {
  display: none;
}

.owl-nav.disabled {
  display: block !important;
}

button.owl-prev span {
  font-size: 60px;
  position: absolute;
  left: 0px;
  top: 24%;
  color: #2BD3EF;
}

button.owl-next span {
  font-size: 60px;
  position: absolute;
  right: 0px;
  top: 24%;
  color: #2BD3EF;
}

.testimonial-item {
  padding: 0px 50px;
}

#intro .intro-info h4 {
  font-size: 21px;
  font-family: 'OpenSans-SemiBold';
  line-height: 28px;
  color: #fff;
}

.footer-content .icon_spectrum,
.footer-content .icon_google,
.footer-content .icon_contribute {
  padding: 5px 0 0px 25px;
  display: inline-block;
  background-size: auto 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.footer-content .icon_spectrum {
  background: url(/assets/img/footer-icons/icon_spectrum.png) no-repeat 0 12px;
  background-size: auto 22px;
}

.footer-content .icon_google {
  background: url(/assets/img/footer-icons/icon_google-group.png) no-repeat 0 10px;
  background-size: auto 22px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.footer-content .icon_contribute {
  background: url(/assets/img/footer-icons/icon_contribute.png) no-repeat 0 10px;
  background-size: auto 22px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.footer-content a:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #ffcc00;
}

.footer-content .icon_spectrum:hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: url(/assets/img/footer-icons/icon_spectrum_hover.png) no-repeat 0 12px;
  background-size: auto 22px;
}

.footer-content .icon_google:hover {
  background: url(/assets/img/footer-icons/icon_google-group_hover.png) no-repeat 0 10px;
  background-size: auto 22px;
}

.footer-content .icon_contribute:hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: url(/assets/img/footer-icons/icon_contribute_hover.png) no-repeat 0 10px;
  background-size: auto 22px;
}

/*------------------Styles for tooltip ---------------------- */

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1.6;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 0px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*-------------------------- Corousel styles --------------------------*/
ul.slides {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  height: 390px;
  /*---height 410 with courosal icons--*/
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.slides input {
  display: none;
}

.slide-container {
  display: block;
  padding: 0px 70px;
}

.slide-image {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: all .7s ease-in-out;
  max-width: 92%;
  margin: 0px auto;
  padding-left: 15px;
}

.slide-image-left-arrow {
  transform: rotate(180deg);
  display: inline-block;
  margin-top: 17px;
}

.carousel-controls {
  position: absolute;
  top: 16%;
  left: 0;
  right: 0;
  z-index: 99;
  font-size: 75px;
  color: #2BD3EF;
  display: none;
}

/* .second-block {
  max-width: 780px;
} */

.carousel-controls label {
  display: none;
  position: absolute;
  padding: 0px;
  cursor: pointer;
}

.slide-image:hover+.carousel-controls label {
  opacity: 0.5;
}

.carousel-controls label:hover {
  opacity: 1;
}

.carousel-controls .prev-slide {
  width: 19%;
  text-align: left;
  left: 0;
}

.carousel-controls .next-slide {
  width: 19%;
  text-align: right;
  right: 0;
}

.carousel-dots {
  z-index: 999;
  text-align: center;
  position: relative;
  top: 410px;
  display: none;
}

.carousel-dots .carousel-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid #2BD3EF;
  opacity: 0.5;
  margin: 10px;
}

input:checked+.slide-container .slide-image {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked+.slide-container .carousel-controls label {
  display: block;
}

input:checked+.slide-container .nav label {
  display: block;
}

span.arrow-icon {
  transform: rotate(270deg);
  display: inline-block;
  font-size: 18px;
  margin-top: 13px;
  margin-right: 0px;
  font-weight: bold;
}

span.arrow-icon:hover {
  color: #fff;
}

input#myInput:focus {
  outline: none;
}

#intro .intro-info {
  position: absolute !important;
  top: 75px;
}

/*----------------------- Header github icon and count css --------------*/

.githubstar-icon-block {
  position: relative;
  width: 175px;
  top: -1px;
  left: 10px;
  border-radius: 4px;
  height: 45px;
  margin-right: 10px;
  cursor: pointer;
}

.githubstar-inner-block {
  position: relative;
  border: 1px solid #2bd3ef;
  width: 69px;
  padding: 6px 9px;
  border-radius: 24px;
  height: 36px;
}

.githubstar-inner-block .fa-github {
  color: #2bd3ef;
  font-size: 21px;
  top: 0px;
  position: relative;
}

.githubstar-inner-block .fa-star {
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: 38px;
  top: 8px;
}

.github-star-count-value {
  position: relative;
  background: #fff;
  top: 0px;
  left: 13px;
  border-radius: 3px;
  width: 50px;
  padding: 5px 6px;
  font-weight: 600;
  height: 34px;
  z-index: 100;
}

.github-star-count-value:after {
  content: '';
  position: absolute;
  width: 12.5px;
  height: 12.5px;
  transform: rotate(45deg);
  background: #fff;
  left: -5px;
  top: 11px;
  z-index: -1;
}

.githubstar-box span {
  padding: 4px 8px !important;
}


/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

@media screen and (max-width: 1323px) and (min-width: 1106px) {
  ul.slides {
    height: 500px;
  }

  #intro .intro-info {
    top: 75px;
  }

  /* .circle_blue {
    margin-bottom: 150px;
  } */
  .carousel-dots {
    z-index: 999;
    text-align: center;
    position: relative;
    top: 470px;
  }
}

@media screen and (max-width: 1105px) and (min-width: 992px) {
  ul.slides {
    height: 585px;
  }

  #intro .intro-info {
    top: 50px;
  }

  .circle_blue {
    margin-bottom: 90px;
  }

  .tag-line {
    margin-top: 75px;
  }

  .carousel-dots {
    z-index: 999;
    text-align: center;
    position: relative;
    top: 555px;
  }

  .slide-image {
    padding-left: 40px;
  }
}

@media (min-width: 991px) {

  #services .description {
    min-height: 112px;
  }

  .footer-content {
    padding: 0px 180px;
  }

  .tag-line p {
    padding: 95px 0px 0px 0px;
  }

  button.toggle-nav {
    display: none;
  }

}

@media (max-width: 991px) {
  #topbar {
    display: none;
  }

  #header {
    height: 70px;
    padding: 15px 0;
  }

  #header .logo h1 {
    font-size: 28px;
    padding: 3px 0px;
  }

  #why-us .why-us-content {
    padding-top: 30px;
  }

  .install-command {
    display: none;
  }

  .circle_blue {
    width: 220px;
    height: 220px;
    background-color: transparent;
    border: 4px solid #2BD3EF;
    display: flex;
    justify-content: center;
    margin: 40px auto;
    border-radius: 50%;
    margin-bottom: 80px;
  }

  #intro .intro-info h2 {
    font-size: 42px;
    line-height: 38px;
  }

  .tag-line {
    margin-top: 50px;
  }

  #intro .intro-info h4 {
    font-family: 'OpenSans-Regular';
    font-size: 21px;
  }

  #intro .intro-info p {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 36px;
  }

  #services .title {
    font-size: 24px;
    line-height: 24px;
  }

  #services .description {
    font-size: 18px;
    min-height: 170px;
  }

  .section-header h3 {
    margin-bottom: 30px;
  }

  .first-block {
    order: 1;
  }

  .second-block {
    order: 3;
  }

  .third-block {
    order: 2;
    position: relative;
    padding-left: 0px;
  }

  #testimonials .testimonial-item p {
    background: transparent;
    color: #fff;
    text-align: left;
    font-size: 16px;
  }

  #testimonials .testimonial-item h3 {
    font-size: 24px;
    font-family: 'PoppinsLatin-Bold';
    margin: 0px 0px 5px 0px;
    position: absolute;
    top: 27%;
    text-align: left;
  }

  #testimonials .testimonial-item .third-block h4 {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 0px;
    position: absolute;
    top: 53%;
    margin-left: 0px;
    text-align: left;
  }

  #testimonials .testimonial-item .testimonial-img {
    border: 0px;
  }

  button.toggle-nav {
    display: none;
  }

  ul.slides {
    height: 400px;
  }

  #testimonials {
    padding: 60px 20px 20px 20px;
  }

  .carousel-dots {
    z-index: 999;
    text-align: center;
    position: relative;
    top: 360px;
  }

  .slide-image {
    padding-left: 60px;
  }

  .carousel-controls {
    position: absolute;
    top: 24%;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }

  #faq #faq-list a {
    font-size: 18px;
  }

  #faq #faq-list i {
    top: 13px;
  }

  #intro .intro-info h4 {
    font-size: 21px;
    font-family: 'OpenSans-Regular';
    line-height: 28px;
    padding-top: 0px;
  }

  #intro .intro-info p {
    font-size: 30px;
  }

  #services .title {
    margin: 30px 0px 25px 0px;
  }

  ul.slides {
    height: 430px;
    margin-top: 0px;
  }

  button.toggle-nav {
    display: block;
  }

  .nav-list>.list>.item {
    display: flex;
    padding: 20px !important;
  }

  .carousel-dots {
    z-index: 999;
    text-align: center;
    position: relative;
    top: 377px;
  }

  .githubstar-inner-block {
    margin-left: -8px;
  }

  #header .logo h1 {
    margin-left: -23px;
  }
}

@media (max-width: 767px) {
  #intro {
    height: auto;
    padding: 80px 0 20px 0;
  }

  #intro .container {
    height: auto !important;
  }

  #intro .intro-img {
    width: 80%;
  }

  #intro .intro-info {
    text-align: center;
    padding-top: 1px;
  }

  #intro .intro-info h2 {
    margin-bottom: 30px;
  }

  #intro .intro-info p {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;  }

  .section-header p {
    width: 100%;
  }

  #testimonials .testimonial-item {
    text-align: center;
  }

  #testimonials .testimonial-item .testimonial-img {
    margin: auto;
  }

  #testimonials .testimonial-item h3,
  #testimonials .testimonial-item h3 span.sub-title,
  #testimonials .testimonial-item p {
    margin-left: 0;
  }

  .carousel-dots {
    z-index: 999;
    text-align: center;
    position: relative;
    top: 398px;
  }

  .circle_blue {
    margin-bottom: 55px;
  }

  .slide-image {
    padding-left: 0px;
  }
}

@media (max-width: 662px) {
  #footer .footer-top .social-links a {
    margin-bottom: 25px;
  }

  img {
    width: 100%;
  }

  #footer {
    text-align: center;
  }

  #intro .intro-info h2 {
    font-size: 36px;
    line-height: 45px;
    word-spacing: 100vw;
    margin-top: 37px;
  }

  #intro .intro-info p {
    color: #fff;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;    line-height: 30px;
    padding: 0px 20px;
    margin-top: 80px;
  }

  .tag-line {
    margin-top: 0px;
  }

  .circle_blue {
    width: 200px;
    height: 200px;
    background-color: transparent;
    border: 4px solid #2BD3EF;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 0px;
  }

  #intro {
    padding-bottom: 0px;
    height: auto;
    padding: 80px 0 250px 0px;
  }

  .section-header h3 {
    font-size: 24px;
    margin-bottom: 50px;
  }

  .features-img-block {
    display: none;
  }

  #services .title {
    margin-top: 30px;
    font-size: 21px;
  }

  #services .description {
    font-size: 16px;
    min-height: auto;
  }

  #testimonials .testimonial-item .testimonial-img {
    border-radius: 50%;
    border: 0px;
  }

  #footer .footer-top .footer-info p {
    padding: 0px 42px;
  }

  .clearfix-block {
    clear: both;
    display: block;
  }

  .tag-line p {
    font-family: 'OpenSans-Regular';
    font-size: 18px !important;
  }

  #testimonials .testimonial-item .third-block h4 {
    font-size: 14px;
    padding: 0px;
    position: absolute;
    top: 46%;
    margin-left: 0px;
    text-align: left;
    width: 90%;
  }

  .owl-nav.disabled {
    display: none !important;
  }

  .testimonial-item {
    padding: 0px;
  }

  .video-section {
    margin-bottom: 60px;
  }

  #services .box {
    padding: 0px 20px 20px 20px;
    margin: 0px 0px 20px 0px;
  }

  .carousel-controls {
    bottom: 0px;
    position: relative;
    margin: 0px auto;
    top: 445px;
    font-size: 45px;
    z-index: 9999;
  }

  ul.slides {
    height: 460px;
    /*---525 with courasol icons--- */
    margin-top: 0px;
  }

  #intro .intro-info h4 {
    font-size: 18px;
    font-family: 'OpenSans-Regular';
    line-height: 28px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  #testimonials .testimonial-item h3 {
    top: 15%;
    font-size: 18px;
    font-family: 'PoppinsLatin-Bold';
    text-align: left;
  }

  .carousel-dots {
    bottom: 0px;
    position: relative;
    margin: 0px auto;
    top: 469px;
  }

  #intro .intro-info {
    position: absolute !important;
    top: 44px;
    margin: 30px auto;
  }

  #testimonials .testimonial-item h3 span.sub-title {
    font-size: 14px;
    line-height: 19px;
    padding-right: 10px;
  }

  #testimonials .testimonial-item p {
    padding: 0px;
    margin-top: 30px;
  }

  #testimonials {
    padding: 40px 0px 20px 0px;
  }

  .slide-container {
    display: block;
    padding: 0px 25px;
  }

  button.toggle-nav {
    display: block;
  }

  .slide-image {
    padding-left: 0px;
    max-width: 100%;
  }
}

// Navbar styles

.toggle-nav {
  background-color: transparent;
  cursor: pointer;
  box-shadow: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0.5rem; 
}

.nav-list {
  background-color: transparent;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  left: -15rem;
  width: 0;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d; 
}
.nav-list.-open {
  width: 100%;
  left: 0;
  padding-bottom: 4.5rem; 
}
.nav-list.-open > .list.-left {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  -webkit-overflow-scrolling: touch; 
}
.nav-list > .list.-left {
  background: #25282d;
  position: relative;
  width: 52%;
  opacity: 0.98;
  height: 100%; 
}
.nav-list > .list.-right {
  width: 40%; 
}
.nav-list > .list > .item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid rgba(176, 176, 176, 0.5); 
}
.nav-list > .list > .item:last-child {
  border-bottom: 0; 
}
.nav-list > .list > .item > .link {
  border-color: transparent;
  padding: 0.75rem 1rem 0.5rem 1rem;
  flex-basis: 100%;
  display: block;
  background: transparent;
  font-size: 1.5rem;
  padding-left: 1.5rem;
  color: #e0e0e0;
  transition: all 0.3s ease-in-out; 
}
.nav-list > .list > .item > .link:hover, .nav-list > .list > .item > .link.-active {
  color: #7dc580;
}
.nav-list > .list > .overlay {
  width: 40%;
  height: 100%;
  background: rgba(59, 59, 59, 0.65);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; 
}
@media all and (min-width: 800px) {
  img {
    width: 80%;
  }
  .nav-list {
    top: unset;
    left: unset;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: initial;
    background: transparent;
    overflow: hidden;
  }
  .nav-list > .list.-left, .nav-list > .list.-right {
    overflow: hidden; 
  }
  .nav-list > .list.-left.-left, .nav-list > .list.-right.-left {
    height: 100%;
    width: 100%;
    border-top: 0;
    background: transparent;
    display: flex;
    align-items: center; 
  }

  .nav-list > .list > .item {
    margin-left: 1.5rem;
    margin-bottom: 0;
    border-bottom-color: transparent;
    justify-content: flex-start; 
  }
  .nav-list > .list > .item > .link {
    border-bottom: 2px solid transparent;
    display: block;
    flex-basis: unset;
    background: transparent;
    padding: 1.25rem 0;
    font-size: 1rem;
    outline: 0;
    transition: all 0.2s ease-in-out; 
  } 
}

._disableScroll {
  overflow-y: hidden !important; 
}

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 10px;
  cursor: pointer;
  -webkit-transition: .2s all;
  -o-transition: .2s all;
  transition: .2s all; }
  .burger:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%; 
  }
  .burger > .burger-lines {
    top: 50%;
    margin-top: -0.125em;
   }
    .burger > .burger-lines:before {
      left: 0;
      top: 1em; 
    }
    .burger > .burger-lines:after {
      left: 0;
      top: -1em; 
    }
  .burger.-offset > .burger-lines {
    top: 50%;
    margin-top: -0.125em; }
    .burger.-offset > .burger-lines:before {
      left: 1em;
      top: 1em; 
    }
    .burger.-offset > .burger-lines:after {
      left: 0;
      top: -1em; 
    }
  .burger.-squeeze .burger-lines,
  .burger.-squeeze .burger-lines:before,
  .burger.-squeeze .burger-lines:after {
    -webkit-transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
    -o-transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
    transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; }
  .burger.-squeeze .burger-lines:after {
    left: 0;
    top: -1em; }
  .burger.-squeeze .burger-lines:before {
    left: 0;
    top: 1em; }
  .burger.-squeeze.-offset .burger-lines:before,
  .burger.-squeeze.-offset .burger-lines:after {
    width: 2em; 
  }
  .burger.-squeeze.-offset .burger-lines:after {
    left: 0;
    top: -1em; 
  }
  .burger.-squeeze.-offset .burger-lines:before {
    left: 1em;
    top: 1em; 
  }
  .burger.-squeeze.open .burger-lines,
  .burger.-squeeze.open .burger-lines:before,
  .burger.-squeeze.open .burger-lines:after {
    -webkit-transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
    -o-transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
    transition: .2s background-color, .2s top, .2s left, .2s transform .15s; 
  }
  .burger.-squeeze.open .burger-lines {
    background-color: transparent; 
  }
  .burger.-squeeze.open .burger-lines:before,
  .burger.-squeeze.open .burger-lines:after {
    left: 0.5em;
    top: 0px; 
  }
  .burger.-squeeze.open .burger-lines:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); 
  }
  .burger.-squeeze.open .burger-lines:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
  }

.burger-lines,
.burger-lines:after,
.burger-lines:before {
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  border-radius: 0.25em;
  background-color: #e0e0e0;
  height: 0.25em;
  position: absolute;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0); 
}

.burger.-squeeze > .burger-lines {
  top: 50%;
  margin-top: -0.125em; 
}
  .burger.-squeeze > .burger-lines, .burger.-squeeze > .burger-lines:after, .burger.-squeeze > .burger-lines:before {
    transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; 
  }
  .burger.-squeeze > .burger-lines:after 
  {
    left: 0;
    top: -1em; 
  }
  .burger.-squeeze > .burger-lines:before {
    left: 0;
    top: 1em; 
  }

.burger.-squeeze.-offset > .burger-lines, .burger.-squeeze.-offset > .burger-lines:after, .burger.-squeeze.-offset > .burger-lines:before {
  transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s; }

.burger.-squeeze.-offset > .burger-lines:after, .burger.-squeeze.-offset > .burger-lines:before {
  width: 3em; }

.burger.-squeeze.-offset > .burger-lines:after {
  left: 0;
  top: -1em; 
}

.burger.-squeeze.-offset > .burger-lines:before {
  left: 0em;
  top: 1em; 
}

.burger.-squeeze.open > .burger-lines, .burger.-squeeze.open > .burger-lines:after, .burger.-squeeze.open > .burger-lines:before {
  transition: .2s background-color, .2s top, .2s left, .2s transform .15s; 
}

.burger.-squeeze.open > .burger-lines:after, .burger.-squeeze.open > .burger-lines:before {
  width: 2em; 
}

.burger.-squeeze.open > .burger-lines {
  background-color: transparent; 
}

.burger.-squeeze.open > .burger-lines:before, .burger.-squeeze.open > .burger-lines:after {
  left: 0.5em;
  top: 0px; 
}

.burger.-squeeze.open > .burger-lines:before {
  transform: rotate(-45deg); 
}

.burger.-squeeze.open > .burger-lines:after {
  transform: rotate(45deg); 
}

.toggle-nav:focus {
  outline: 1px solid #2BD3EF;
}

code {
  color: #25282D;
}
